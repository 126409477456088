import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Autocomplete,
    TextField,
    Typography,
    Box,
    IconButton,
    CircularProgress,
    MenuItem,
    Menu,
    useTheme,
    Chip,
} from "@mui/material";
import * as React from "react";
import { Column, isRealRow, Row } from "../../grid/grid";
import { getStringValuesFromValue } from "./visibility";
import { ColumnVisibilityFilter, VisibilityFiltersByColumn, NumberVisibilityFilter } from "../../grid/grid";
import { ValueChip } from "../filters/valueChip";
import { useBoolean } from "../../utils/hooks";
import { Add, ArrowDown2, ArrowRight2, PlayCircle, Trash } from "iconsax-react";
import { Close } from "@mui/icons-material";
import { comparatorOnFields } from "../../utils/comparators";
import { NumberInput } from "../../reusable/numberInput";

const conditionVisibilityOptions = {
    "is-null": { label: "Is missing", value: "is-null" },
    "is-not-null": { label: "Is not missing", value: "is-not-null" },
} as const;
function areOptionsEqual(a: VisibilityOptionsType, b: VisibilityOptionsType) {
    return a.value === b.value;
}

interface VisibilityFilterDialogProps {
    allRows: Row[];
    isOpen: boolean;
    column: Column;
    visibilityFilters: ColumnVisibilityFilter | undefined;
    isExportOngoing: boolean;
    onLoadAllUnloadedCells: (() => Promise<void>) | undefined;
    onClose: () => void;
    setVisibilityFiltersByColumn: React.Dispatch<React.SetStateAction<VisibilityFiltersByColumn>>;
}

type VisibilityOptionsType = (typeof conditionVisibilityOptions)[keyof typeof conditionVisibilityOptions];

export const VisibilityFilterDialog: React.FC<VisibilityFilterDialogProps> = ({
    allRows,
    isOpen,
    onClose,
    column,
    visibilityFilters,
    onLoadAllUnloadedCells,
    setVisibilityFiltersByColumn,
    isExportOngoing,
}) => {
    const { value: isFilterConditionsCollapsed, toggleValue: toggleFilterConditionsCollapsed } = useBoolean(false);
    const { value: isFilterValuesCollapsed, toggleValue: toggleFilterValuesCollapsed } = useBoolean(false);
    const [loadUnloadCellsWasJustClicked, setLoadUnloadCellsWasJustClicked] = React.useState(false);

    const handleLoadUnloadCells = React.useCallback(async () => {
        if (onLoadAllUnloadedCells != null) {
            setLoadUnloadCellsWasJustClicked(true);
            setTimeout(() => {
                setLoadUnloadCellsWasJustClicked(false);
            }, 2000);
            await onLoadAllUnloadedCells();
        }
    }, [onLoadAllUnloadedCells]);

    const isBooleanColumn =
        column.generatedBy?.type === "web_search" && column.generatedBy.formatOptions?.type === "bool";

    const isNumberColumn =
        column.generatedBy?.type === "web_search" && column.generatedBy.formatOptions?.type === "number";

    // TODO: This is quite brittle
    const isStringColumn = !isNumberColumn && !isBooleanColumn && column.generatedBy?.type !== "apollo";

    const handleCancel = React.useCallback(() => {
        onClose();
    }, [onClose]);

    const handleSave = React.useCallback(() => {
        // TODO: Save logic here??
        onClose();
    }, [onClose]);

    const handleChangeNullFilter = React.useCallback(
        (_: React.SyntheticEvent, value: Array<VisibilityOptionsType | null>) => {
            const newNullFilter = value.find(option => option?.value === "is-null" || option?.value === "is-not-null");
            setVisibilityFiltersByColumn(prev => ({
                ...prev,
                [column.id]: {
                    ...prev[column.id],
                    nullFilter: newNullFilter != null ? { type: newNullFilter.value } : undefined,
                },
            }));
        },
        [column.id, setVisibilityFiltersByColumn],
    );

    const selectedValues = React.useMemo(() => {
        const nullFilter = visibilityFilters?.nullFilter;
        return nullFilter != null
            ? [
                  nullFilter.type === "is-not-null"
                      ? conditionVisibilityOptions["is-not-null"]
                      : conditionVisibilityOptions["is-null"],
              ]
            : [];
    }, [visibilityFilters?.nullFilter]);

    const filteredOptions = React.useMemo((): VisibilityOptionsType[] => {
        const nullFilter = visibilityFilters?.nullFilter;
        if (nullFilter != null) {
            return selectedValues;
        }
        return [conditionVisibilityOptions["is-null"], conditionVisibilityOptions["is-not-null"]];
    }, [visibilityFilters?.nullFilter, selectedValues]);

    const getValueTitle = React.useCallback((option: VisibilityOptionsType | undefined) => option?.label ?? "None", []);

    const booleanFilter = React.useMemo(() => {
        if (!isBooleanColumn) {
            return null;
        }
        return visibilityFilters?.booleanFilter;
    }, [isBooleanColumn, visibilityFilters]);

    const handleChangeBooleanVisibilityFilterValue = React.useCallback(
        (value: boolean | undefined) => {
            setVisibilityFiltersByColumn(prev => ({
                ...prev,
                [column.id]: {
                    ...prev[column.id],
                    booleanFilter: value != null ? { value, type: "boolean" } : undefined,
                },
            }));
        },
        [column.id, setVisibilityFiltersByColumn],
    );

    const stringFilter = React.useMemo(() => {
        if (!isStringColumn) {
            return null;
        }
        return visibilityFilters?.stringFilter;
    }, [isStringColumn, visibilityFilters]);

    const handleChangeStringVisibilityFilterValue = React.useCallback(
        (value: string[]) => {
            setVisibilityFiltersByColumn(prev => ({
                ...prev,
                [column.id]: {
                    ...prev[column.id],
                    stringFilter: value.length > 0 ? { value, type: "contains" } : undefined,
                },
            }));
        },
        [column.id, setVisibilityFiltersByColumn],
    );

    // TODO: Native array filter support
    const isStringColumnWithArrayValue =
        (stringFilter?.value.length ?? 0) === 0 &&
        column.generatedBy?.type === "crunchbase" &&
        (column.generatedBy.fieldId === "investor_identifiers" ||
            column.generatedBy.fieldId === "categories" ||
            column.generatedBy.fieldId === "category_groups");

    const handleChangeNumberVisibilityFilterValue = React.useCallback(
        (newFilter: NumberVisibilityFilter) => {
            setVisibilityFiltersByColumn(prev => ({
                ...prev,
                [column.id]: {
                    ...prev[column.id],
                    numberFilter: newFilter,
                },
            }));
        },
        [column.id, setVisibilityFiltersByColumn],
    );

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
            <DialogTitle
                sx={{
                    maxWidth: 500,
                }}
                noWrap
            >
                {`Filter by Column Answers - '${column.label}'`}
            </DialogTitle>
            <DialogContent
                sx={{
                    height: 240,
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    maxWidth: "md",
                    width: 500,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 0.5,
                    }}
                >
                    <CollapsibleFilterLabel
                        label="Filter by condition"
                        isCollapsed={isFilterConditionsCollapsed}
                        onToggle={toggleFilterConditionsCollapsed}
                    />
                    {!isFilterConditionsCollapsed && (
                        <Autocomplete
                            multiple
                            filterSelectedOptions
                            isOptionEqualToValue={areOptionsEqual}
                            options={filteredOptions}
                            value={selectedValues}
                            onChange={handleChangeNullFilter}
                            getOptionLabel={getValueTitle}
                            renderTags={(value: readonly VisibilityOptionsType[], getTagProps) =>
                                value.map((option: VisibilityOptionsType, index: number) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return <ValueChip {...tagProps} label={getValueTitle(option)} key={key} />;
                                })
                            }
                            renderInput={params => (
                                <TextField {...params} placeholder="Select a condition" variant="outlined" />
                            )}
                            size="small"
                            sx={{
                                pl: 0.5,
                                typography: "caption",
                            }}
                        />
                    )}
                </Box>
                {isBooleanColumn && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 0.5,
                        }}
                    >
                        <CollapsibleFilterLabel
                            label="Filter by value"
                            isCollapsed={isFilterValuesCollapsed}
                            onToggle={toggleFilterValuesCollapsed}
                        />
                        {!isFilterValuesCollapsed && (
                            <BooleanFilterByValueValuePicker
                                value={booleanFilter?.value}
                                onChange={handleChangeBooleanVisibilityFilterValue}
                            />
                        )}
                    </Box>
                )}
                {isStringColumn && !isStringColumnWithArrayValue && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 0.5,
                        }}
                    >
                        <CollapsibleFilterLabel
                            label="Filter by value"
                            isCollapsed={isFilterValuesCollapsed}
                            onToggle={toggleFilterValuesCollapsed}
                        />
                        {!isFilterValuesCollapsed && (
                            <StringFilterByValuePicker
                                allRows={allRows}
                                column={column}
                                values={stringFilter?.value ?? []}
                                onChange={handleChangeStringVisibilityFilterValue}
                            />
                        )}
                    </Box>
                )}
                {(isNumberColumn ||
                    visibilityFilters?.numberFilter?.eq != null ||
                    visibilityFilters?.numberFilter?.gte != null ||
                    visibilityFilters?.numberFilter?.lte != null) && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: 0.5,
                        }}
                    >
                        <CollapsibleFilterLabel
                            label="Filter by value"
                            isCollapsed={isFilterValuesCollapsed}
                            onToggle={toggleFilterValuesCollapsed}
                        />
                        {!isFilterValuesCollapsed && (
                            <NumberFilterByValuePicker
                                allRows={allRows}
                                column={column}
                                filter={visibilityFilters?.numberFilter}
                                onChange={handleChangeNumberVisibilityFilterValue}
                            />
                        )}
                    </Box>
                )}
                {onLoadAllUnloadedCells == null && (
                    <Typography variant="caption">
                        Visibility filters apply to <strong>loaded</strong> rows. For more results, generate more rows.
                    </Typography>
                )}
                {onLoadAllUnloadedCells != null && (
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 0.5 }}>
                        <Typography variant="caption" color="warning.main">
                            This column has unloaded cells on other pages. Consider loading all column values before
                            filtering.
                        </Typography>
                        <Button
                            disabled={isExportOngoing || loadUnloadCellsWasJustClicked}
                            startIcon={isExportOngoing ? <CircularProgress size={16} /> : <PlayCircle size={16} />}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleLoadUnloadCells}
                            color="secondary"
                            variant="outlined"
                            sx={{
                                flexShrink: 0,
                            }}
                        >
                            <Typography variant="body2" noWrap>
                                Load cells
                            </Typography>
                        </Button>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleCancel}
                    sx={{
                        textTransform: "none",
                        typography: "body2",
                        borderRadius: 8,
                    }}
                    variant="text"
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    sx={{
                        textTransform: "none",
                        borderRadius: 8,
                        typography: "body2",
                    }}
                    variant="contained"
                    color="secondary"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface CollapsibleFilterLabelProps {
    label: string;
    isCollapsed: boolean;
    onToggle: () => void;
}

const CollapsibleFilterLabel: React.FC<CollapsibleFilterLabelProps> = ({ label, isCollapsed, onToggle }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
            }}
        >
            <IconButton size="small" onClick={onToggle}>
                {isCollapsed ? <ArrowRight2 size={16} /> : <ArrowDown2 size={16} />}
            </IconButton>
            <Typography
                variant="body2"
                sx={{
                    fontWeight: "medium",
                }}
            >
                {label}
            </Typography>
        </Box>
    );
};

interface BooleanFilterByValueValuePickerProps {
    value: boolean | undefined;
    onChange: (value: boolean | undefined) => void;
}

const OPTIONS = ["Yes", "No"] as const;
const BooleanFilterByValueValuePicker: React.FC<BooleanFilterByValueValuePickerProps> = ({ value, onChange }) => {
    const handleChange = React.useCallback(
        (event: React.SyntheticEvent<Element>, value: "Yes" | "No" | null) => {
            if (value == null) {
                return onChange(undefined);
            }
            onChange(value === "Yes");
        },
        [onChange],
    );

    return (
        <Autocomplete<"Yes" | "No" | null, false>
            options={OPTIONS}
            value={value != null ? (value ? "Yes" : "No") : null}
            clearIcon={<Close fontSize="small" />}
            onChange={handleChange}
            renderInput={params => <TextField {...params} variant="outlined" placeholder="Select values" hiddenLabel />}
            size="small"
        />
    );
};

interface StringFilterByValuePickerProps {
    allRows: Row[];
    column: Column;
    values: string[];
    onChange: (values: string[]) => void;
}

const StringFilterByValuePicker: React.FC<StringFilterByValuePickerProps> = ({ allRows, column, values, onChange }) => {
    const [options, setOptions] = React.useState<string[]>([]);

    const handleChange = React.useCallback(
        (_: React.SyntheticEvent, newValues: string[]) => {
            onChange(newValues);
        },
        [onChange],
    );

    const computeOptions = React.useCallback(() => {
        const uniqueValues = new Set<string>();
        const showOptionsDespiteHighSize = column.generatedBy?.type === "apollo_organization";
        for (const row of allRows) {
            if (isRealRow(row)) {
                const cellValue = row.data[column.id];
                const stringValues = getStringValuesFromValue(cellValue);
                for (const value of stringValues) {
                    uniqueValues.add(value);
                }
                if (uniqueValues.size > 200 && !showOptionsDespiteHighSize) {
                    setOptions([]);
                    return;
                }
            }
        }
        setOptions(Array.from(uniqueValues).sort(comparatorOnFields(v => [v])));
    }, [allRows, column.generatedBy?.type, column.id]);

    return (
        <Autocomplete
            multiple
            freeSolo
            options={options}
            value={values}
            onChange={handleChange}
            onFocus={computeOptions}
            renderInput={params => (
                <TextField {...params} variant="outlined" placeholder="Enter string values" size="small" fullWidth />
            )}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <ValueChip {...getTagProps({ index })} label={option} key={option} />
                ))
            }
        />
    );
};

interface NumberFilterByValuePickerProps {
    allRows: Row[];
    column: Column;
    filter: NumberVisibilityFilter | undefined;
    onChange: (filter: NumberVisibilityFilter) => void;
}

const NumberFilterByValuePicker: React.FC<NumberFilterByValuePickerProps> = ({ allRows, column, filter, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [lteValue, setLteValue] = React.useState<number | null>(filter?.lte ?? null);
    const [gteValue, setGteValue] = React.useState<number | null>(filter?.gte ?? null);
    const open = Boolean(anchorEl);

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleAddFilter = React.useCallback(
        (type: "gte" | "lte" | "eq") => {
            onChange({
                ...(filter ?? { type: "number", gte: undefined, lte: undefined, eq: undefined }),
                [type]: type === "eq" ? [] : 0,
            });
            handleClose();
        },
        [filter, onChange, handleClose],
    );

    const handleRemoveFilter = React.useCallback(
        (type: "gte" | "lte" | "eq") => {
            const newFilter = { ...(filter ?? { type: "number", gte: undefined, lte: undefined, eq: undefined }) };
            newFilter[type] = undefined;
            onChange(newFilter);
        },
        [filter, onChange],
    );

    const handleDeleteGteAndLte = React.useCallback(() => {
        const newFilter = { ...(filter ?? { type: "number", gte: undefined, lte: undefined, eq: undefined }) };
        newFilter.gte = undefined;
        newFilter.lte = undefined;
        onChange(newFilter);
        setGteValue(null);
        setLteValue(null);
    }, [filter, onChange]);

    const handleDeleteEq = React.useCallback(() => {
        handleRemoveFilter("eq");
    }, [handleRemoveFilter]);

    const handleChangeGte = React.useCallback(
        (value: number | null) => {
            setGteValue(value);
            onChange({
                ...(filter ?? { type: "number", gte: undefined, lte: undefined, eq: undefined }),
                gte: value ?? 0,
            });
        },
        [filter, onChange],
    );

    const handleChangeLte = React.useCallback(
        (value: number | null) => {
            setLteValue(value);
            onChange({
                ...(filter ?? { type: "number", gte: undefined, lte: undefined, eq: undefined }),
                lte: value ?? 0,
            });
        },
        [filter, onChange],
    );

    const handleChangeEq = React.useCallback(
        (_: React.SyntheticEvent, newValues: (string | number)[]) => {
            const numericValues = newValues.filter(v => !isNaN(Number(v))).map(Number);
            onChange({
                ...(filter ?? { type: "number", gte: undefined, lte: undefined, eq: undefined }),
                eq: numericValues.length > 0 ? numericValues : undefined,
            });
        },
        [filter, onChange],
    );

    const theme = useTheme();

    const handleAddGte = React.useCallback(() => {
        handleAddFilter("gte");
    }, [handleAddFilter]);

    const handleAddLte = React.useCallback(() => {
        handleAddFilter("lte");
    }, [handleAddFilter]);

    const handleAddEq = React.useCallback(() => {
        handleAddFilter("eq");
    }, [handleAddFilter]);

    const eqValues = React.useMemo(() => {
        return filter?.eq ?? [];
    }, [filter]);

    const valuesInColumn = React.useMemo(() => {
        if (filter?.eq == null) {
            return [];
        }
        const uniqueValues = new Set<number>();
        for (const row of allRows) {
            if (isRealRow(row)) {
                const cellValue = row.data[column.id];
                if (cellValue.value.type === "number") {
                    uniqueValues.add(Number(cellValue.value.value.toFixed(3)));
                }
            }
        }
        return Array.from(uniqueValues).sort(comparatorOnFields(v => [v]));
    }, [allRows, column.id, filter?.eq]);

    const getOptionLabel = React.useCallback((option: string | number) => {
        return option.toLocaleString();
    }, []);

    const isOptionEqualToValue = React.useCallback((option: string | number, value: string | number) => {
        return option.toString() === value.toString();
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, alignItems: "stretch" }}>
            {(filter?.lte != null || filter?.gte != null) && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 1,
                        justifyContent: "stretch",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, flexGrow: 1 }}>
                        {filter?.gte != null && (
                            <>
                                <NumberInput
                                    type="number"
                                    value={gteValue}
                                    onChange={handleChangeGte}
                                    size="small"
                                    variant="standard"
                                    inputProps={{
                                        sx: {
                                            height: 24,
                                            width: 100,
                                            maxWidth: 140,
                                            p: 0,
                                            "&::-webkit-inner-spin-button": { WebkitAppearance: "none" },
                                            "&.input[type=number]": { MozAppearance: "textfield" },
                                            borderColor: "divider",
                                            borderBottom: `1px solid ${theme.palette.divider}`,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        alignSelf: "flex-end",
                                        height: 24,
                                    }}
                                />

                                <Typography variant="body1" sx={{ px: 0.5 }}>
                                    {"≤"}
                                </Typography>
                            </>
                        )}
                        <Chip label="Cell value" variant="outlined" size="small" />
                        {filter?.lte != null && (
                            <>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        px: 0.5,
                                    }}
                                >
                                    {"≤"}
                                </Typography>
                                <NumberInput
                                    type="number"
                                    value={lteValue}
                                    onChange={handleChangeLte}
                                    size="small"
                                    variant="standard"
                                    inputProps={{
                                        sx: {
                                            height: 24,
                                            width: 100,
                                            maxWidth: 140,
                                            p: 0,
                                            "&::-webkit-inner-spin-button": { WebkitAppearance: "none" },
                                            "&.input[type=number]": { MozAppearance: "textfield" },
                                            borderColor: "divider",
                                            borderBottom: `1px solid ${theme.palette.divider}`,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        alignSelf: "flex-end",
                                        height: 24,
                                    }}
                                />
                            </>
                        )}
                    </Box>
                    <IconButton
                        id="delete-gte-and-lte-filter-button"
                        onClick={handleDeleteGteAndLte}
                        sx={{
                            alignSelf: "flex-end",
                        }}
                        color="secondary"
                    >
                        <Trash size={16} />
                    </IconButton>
                </Box>
            )}
            {filter?.eq != null && (
                <Autocomplete
                    multiple
                    freeSolo
                    options={valuesInColumn}
                    value={eqValues}
                    onChange={handleChangeEq}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    disableClearable
                    renderInput={params => (
                        <TextField
                            {...params}
                            size="small"
                            placeholder="Enter numeric values"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <IconButton onClick={handleDeleteEq}>
                                        <Close sx={{ width: 16, height: 16 }} />
                                    </IconButton>
                                ),
                                sx: {
                                    py: "2px !important",
                                    typography: "body2",
                                },
                            }}
                        />
                    )}
                    renderTags={(value: readonly number[], getTagProps) =>
                        value.map((option: number, index: number) => (
                            <ValueChip
                                {...getTagProps({ index })}
                                label={option}
                                key={option}
                                onDelete={() => {
                                    const newEq = filter.eq?.filter((_, i) => i !== index);
                                    onChange({
                                        ...filter,
                                        eq: newEq?.length ? newEq : [],
                                    });
                                }}
                            />
                        ))
                    }
                />
            )}
            {(filter?.eq == null || filter?.gte != null || filter?.lte != null) && (
                <Button
                    color="secondary"
                    startIcon={<Add size={16} color={theme.palette.secondary.main} />}
                    onClick={handleClick}
                    size="small"
                    sx={{ alignSelf: "flex-start", borderRadius: 8 }}
                >
                    Add value filter
                </Button>
            )}
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {filter?.gte == null && (
                    <MenuItem
                        onClick={handleAddGte}
                        sx={{
                            typography: "body2",
                        }}
                    >
                        Greater than or equal to
                    </MenuItem>
                )}
                {filter?.lte == null && (
                    <MenuItem
                        onClick={handleAddLte}
                        sx={{
                            typography: "body2",
                        }}
                    >
                        Less than or equal to
                    </MenuItem>
                )}
                {filter?.eq == null && (
                    <MenuItem
                        onClick={handleAddEq}
                        sx={{
                            typography: "body2",
                        }}
                    >
                        Equal to
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};
