import * as React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash-es/isEqual";
import {
    Column,
    Row,
    isRealRow,
    isPlaceholderRow,
    generateRandomColumnId,
    NEW_COLUMN_PLACEHOLDER,
    RealRow,
    RowGeneratingFilters,
    ColumnGeneratedBy,
    RowKey,
    getStringKeyFromRow,
    randomUniqueId,
    ExportMode,
    CellValueV2String,
    LoadingStatus,
    RowValueWithCitations,
    CellValueV2,
    ApolloOrganizationField,
} from "./grid";
import { AbstractBackendService, BackendService } from "../services/cb-backend";
import {
    CompleteColumnForQuery,
    asCompleteColumnForQuery,
    useUpdateColumnsWithQuery,
    useUpdateRowsWithQuery,
} from "./useUpdateQueries";
import {
    convertBackendGridToFrontend,
    convertFrontendGridDataToGridData,
    convertFrontendRowGeneratingFilters,
} from "./gridSerialization";
import { GridFillUpToNRowsRequest } from "../services/cb-backend-types";
import { FREE_TRIAL_QUERY_KEY_FIRST_PART, getReactQueryGridKey, getReactQueryTaskKey } from "../react-query/queryKeys";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { CRUNCHBASE_FIELD_DISPLAY_NAMES } from "../services/crunchbase";

const EMPTY_CELL_VALUE: RowValueWithCitations<CellValueV2> = {
    value: { type: "missing" },
    citations: [],
    sourceDetails: undefined,
};

export const useColumnEdits = (
    columns: Column[],
    rowsInPage: Row[],
    setColumns: React.Dispatch<React.SetStateAction<Column[]>>,
    setRows: React.Dispatch<React.SetStateAction<Row[]>>,
    setCellGenerationStatusesByColByRow: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, LoadingStatus | undefined>>>
    >,
    collectionTypeName: string,
) => {
    const { onAddColumnValues } = useOnAddColumnValues(
        setColumns,
        setRows,
        setCellGenerationStatusesByColByRow,
        collectionTypeName,
    );

    const onAddColumnValuesToThisPage = React.useCallback(
        async (colId: string, columnQuery: ColumnGeneratedBy, colLabel?: string) =>
            onAddColumnValues(
                [{ colId, generatedBy: columnQuery, colLabel, rows: rowsInPage.filter(isRealRow) }],
                columns,
            ),
        [columns, onAddColumnValues, rowsInPage],
    );

    const handleDeleteColumn = React.useCallback(
        (colId: string) => {
            setColumns(prevColumns =>
                prevColumns
                    .filter(column => column.id !== colId)
                    .map<Column>(c => dropContextColumnIdIfExists(c, colId)),
            );
            setRows(prevRows =>
                prevRows.map(row => {
                    if (isPlaceholderRow(row)) return row;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { [colId]: _, ...rest } = row.data;
                    return {
                        ...row,
                        type: "real",
                        data: { ...rest, name: row.data.name },
                    };
                }),
            );
        },
        [setColumns, setRows],
    );

    const handleAddColumn = React.useCallback(() => {
        const id = generateRandomColumnId();
        setColumns(columns => [...columns, { ...NEW_COLUMN_PLACEHOLDER, id }]);
        setRows(prevRows =>
            prevRows.map(row => (isRealRow(row) ? { ...row, data: { ...row.data, [id]: EMPTY_CELL_VALUE } } : row)),
        );
    }, [setColumns, setRows]);

    const onChangeCellValue = React.useCallback(
        (rowStringKey: string, colId: string, value: CellValueV2String) => {
            setRows(prevRows =>
                prevRows.map(row =>
                    isRealRow(row) && getStringKeyFromRow(row) === rowStringKey
                        ? {
                              ...row,
                              data: {
                                  ...row.data,
                                  [colId]: {
                                      value,
                                      citations: [],
                                      sourceDetails: undefined,
                                  },
                              },
                          }
                        : row,
                ),
            );
        },
        [setRows],
    );

    return { onAddColumnValuesToThisPage, onAddColumnValues, handleDeleteColumn, handleAddColumn, onChangeCellValue };
};

export interface ColQueryWithRow {
    colId: string;
    generatedBy: ColumnGeneratedBy;
    rows: RealRow[];
    colLabel?: string;
}

export function useOnAddColumnValues(
    setColumns: React.Dispatch<React.SetStateAction<Column[]>>,
    setRows: React.Dispatch<React.SetStateAction<Row[]>>,
    setCellGenerationStatusesByColByRow: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, LoadingStatus | undefined>>>
    >,
    collectionTypeName: string,
) {
    const service = React.useMemo(() => new BackendService(), []);
    const updateColumnsWithQuery = useUpdateColumnsWithQuery(service);
    const onAddColumnValues = React.useCallback(
        async (colQuery: ColQueryWithRow[], allColumns: Column[]) => {
            const columnsToUpdate = colQuery.filter(
                ({ generatedBy }) => !(generatedBy.type === "web_search" && generatedBy.query.trim() === ""),
            );
            if (columnsToUpdate.length === 0) {
                return;
            }

            setColumns(prevColumns =>
                prevColumns.map((column: Column) => {
                    const updateInfo = columnsToUpdate.find(c => c.colId === column.id);
                    if (updateInfo) {
                        return {
                            ...column,
                            isEditable: false,
                            label: updateInfo.colLabel ?? getLabelFromColQuery(updateInfo.generatedBy),
                            generatedBy: updateInfo.generatedBy,
                        };
                    }
                    return column;
                }),
            );

            const columnsWithGeneratedByChanges = columnsToUpdate.filter(({ colId, generatedBy }) => {
                const existingCol = allColumns.find(col => col.id === colId);
                return existingCol == null || !isEqual(generatedBy, existingCol.generatedBy);
            });
            const columnsWithGeneratedByChangesIds = new Set(columnsWithGeneratedByChanges.map(({ colId }) => colId));

            const columnsForQuery: Array<{ rows: Row[]; colQuery: CompleteColumnForQuery }> = columnsToUpdate
                .map(({ colId, generatedBy: columnQuery, colLabel, rows }) => ({
                    rows,
                    colQuery: asCompleteColumnForQuery({
                        id: colId,
                        generatedBy: columnQuery,
                        label: colLabel ?? getLabelFromColQuery(columnQuery),
                        align: undefined,
                    }),
                }))
                .filter(
                    (colAndRows): colAndRows is { rows: RealRow[]; colQuery: CompleteColumnForQuery } =>
                        colAndRows.colQuery != null,
                )
                .map(({ rows, colQuery }) => {
                    const colHasChanges = columnsWithGeneratedByChangesIds.has(colQuery.id);
                    return {
                        // If the column doesn't have changes, we only need to load cells for the unloaded rows
                        rows: colHasChanges
                            ? rows
                            : rows.filter(row => row.data[colQuery.id].value.type === "unloaded"),
                        colQuery,
                    };
                })
                .filter(({ rows }) => rows.length > 0);

            // Only rename
            if (columnsForQuery.length === 0) {
                return;
            }

            // Set all the cells in the rows to unloaded if the column is being added or updated
            if (columnsWithGeneratedByChanges.length > 0) {
                setRows(prevRows =>
                    prevRows.map(row => {
                        if (isRealRow(row)) {
                            const updatedData = { ...row.data };
                            columnsWithGeneratedByChanges.forEach(({ colId }) => {
                                updatedData[colId] = { ...updatedData[colId], value: { type: "unloaded" } };
                            });
                            return { ...row, data: updatedData };
                        }
                        return row;
                    }),
                );
            }

            try {
                await updateColumnsWithQuery(
                    columnsForQuery.map(({ rows, colQuery }) => ({ column: colQuery, rows })),
                    allColumns,
                    setColumns,
                    setRows,
                    setCellGenerationStatusesByColByRow,
                    collectionTypeName,
                );
            } catch (error) {
                console.error("Failed to generate columns", error);
                enqueueSnackbar("Failed to generate columns", { variant: "error" });
                columnsToUpdate.forEach(({ colId }) => {
                    setCellGenerationStatusesByColByRow(prev => ({
                        ...prev,
                        [colId]: {},
                    }));
                });
            }
        },
        [setColumns, setRows, updateColumnsWithQuery, setCellGenerationStatusesByColByRow, collectionTypeName],
    );

    return { onAddColumnValues };
}

function dropContextColumnIdIfExists(column: Column, colId: string): Column {
    if (column.generatedBy?.type === "web_search") {
        return {
            ...column,
            generatedBy: {
                ...column.generatedBy,
                contextColumnIds: column.generatedBy.contextColumnIds.filter(id => id !== colId),
            },
        };
    }
    return column;
}

export const useRowEdits = (
    columns: Column[],
    rows: Row[],
    setColumns: React.Dispatch<React.SetStateAction<Column[]>>,
    setRows: React.Dispatch<React.SetStateAction<Row[]>>,
    setRowGenerationStatuses: React.Dispatch<React.SetStateAction<Record<string, LoadingStatus | undefined>>>,
    rowGenerationStatuses: Record<string, LoadingStatus | undefined>,
    collectionTypeName: string,
) => {
    const updateRowsWithQuery = useUpdateRowsWithQuery(new BackendService());

    const handleEditKeyColumnCell = React.useCallback(
        async (colId: "name", prevKeyValue: string, newKeyValue: string): Promise<void> => {
            const nonEditedColumns = columns
                .filter(column => column.id !== colId)
                .map(asCompleteColumnForQuery)
                .filter((column): column is CompleteColumnForQuery => column != null);
            setRows(prevRows =>
                prevRows.map(row =>
                    isRealRow(row) && row.data[colId].value.value === prevKeyValue
                        ? {
                              ...row,
                              type: "real",
                              data: {
                                  ...row.data,
                                  ...nonEditedColumns.reduce(
                                      (acc, column) => ({ ...acc, [column.id]: { type: "missing" } }),
                                      {},
                                  ),
                                  [colId]: {
                                      value: { type: "string", value: newKeyValue },
                                      citations: [],
                                      sourceDetails: undefined,
                                  },
                              },
                          }
                        : row,
                ),
            );
            const rowKeyTitle = nonEditedColumns.find(column => column.id === "name")?.id ?? "Name";
            const singleRowKey: RowKey[] = [{ title: rowKeyTitle, value: newKeyValue, externalIdentifiers: undefined }];
            setRowGenerationStatuses(prev => ({ ...prev, [newKeyValue]: "loading" }));

            try {
                await updateRowsWithQuery(
                    singleRowKey,
                    colId,
                    nonEditedColumns,
                    rows,
                    setRows,
                    setRowGenerationStatuses,
                    collectionTypeName,
                );
                setRowGenerationStatuses(prev => ({ ...prev, [newKeyValue]: undefined }));
            } catch (error) {
                console.error("Failed to regenerate row", error);
                enqueueSnackbar("Failed to regenerate row", { variant: "error" });
            }
        },
        [collectionTypeName, columns, rows, setRowGenerationStatuses, setRows, updateRowsWithQuery],
    );

    const handleDeleteRow = React.useCallback(
        (rowStringKey: string) =>
            setRows(prevRows => prevRows.filter(row => getStringKeyFromRow(row) !== rowStringKey)),
        [setRows],
    );

    const handleOnAddRowWithRowKeyValue = React.useCallback(
        async (rowKeyColId: "name", rowKeyValue: string, rowStringKey: string) => {
            if (rowKeyValue.trim() === "") {
                return;
            }
            if (rows.find(row => isRealRow(row) && row.data[rowKeyColId].value.value === rowKeyValue)) {
                setRows(prevRows => prevRows.filter(row => getStringKeyFromRow(row) !== rowStringKey));
                return;
            }
            const nonEditedColumns = columns
                .filter(column => column.id !== rowKeyColId)
                .map(asCompleteColumnForQuery)
                .filter((column): column is CompleteColumnForQuery => column != null);
            const userInputColumns = columns.filter(column => column.generatedBy?.type === "user_input");

            setRows(prevRows => {
                const newRow: RealRow = {
                    type: "real",
                    imageUrl: undefined,
                    externalIdentifiers: undefined,
                    data: {
                        ...nonEditedColumns.reduce((acc, column) => ({ ...acc, [column.id]: EMPTY_CELL_VALUE }), {}),
                        ...userInputColumns.reduce((acc, column) => ({ ...acc, [column.id]: EMPTY_CELL_VALUE }), {}),
                        [rowKeyColId]: {
                            value: { type: "string", value: rowKeyValue },
                            citations: [],
                            sourceDetails: undefined,
                        },
                    },
                };
                const rowIndex = rows.findIndex(row => getStringKeyFromRow(row) === rowStringKey);
                const newRows = [...prevRows.slice(0, rowIndex), newRow, ...prevRows.slice(rowIndex + 1)];
                return rowIndex === prevRows.length - 1
                    ? [...newRows, { type: "placeholder", name: "", uniqueId: randomUniqueId() }]
                    : newRows;
            });
            setIsLastPlaceholderRowFocused(true);
            const singleRowKey: RowKey[] = [{ title: rowKeyColId, value: rowKeyValue, externalIdentifiers: undefined }];
            await updateRowsWithQuery(
                singleRowKey,
                rowKeyColId,
                nonEditedColumns,
                rows,
                setRows,
                setRowGenerationStatuses,
                collectionTypeName,
            );
        },
        [rows, columns, setRows, updateRowsWithQuery, setRowGenerationStatuses, collectionTypeName],
    );

    const handleStartAddingNewRows = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            e.preventDefault();
            setIsLastPlaceholderRowFocused(true);
            setRows(prevRows => [...prevRows, { type: "placeholder", name: "", uniqueId: randomUniqueId() }]);
        },
        [setRows],
    );

    const handleChangePlaceholderRowValue = React.useCallback(
        (rowStringKey: string, val: string) => {
            setRows(prevRows =>
                prevRows.map(row =>
                    isPlaceholderRow(row) && getStringKeyFromRow(row) === rowStringKey ? { ...row, name: val } : row,
                ),
            );
        },
        [setRows],
    );

    const [isLastPlaceholderRowFocused, setIsLastPlaceholderRowFocused] = React.useState(true);

    const handleBlurLastRow = React.useCallback(
        (rowStringKey: string) => {
            const row = rows.find(row => getStringKeyFromRow(row) === rowStringKey);
            if (row == null || !isPlaceholderRow(row)) {
                return;
            }
            if (row.name === "") {
                setRows(prevRows => prevRows.slice(0, prevRows.length - 1));
            }
            setIsLastPlaceholderRowFocused(false);
        },
        [rows, setRows],
    );

    return {
        handleEditKeyColumnCell,
        handleDeleteRow,
        handleOnAddRowWithRowKey: handleOnAddRowWithRowKeyValue,
        handleStartAddingNewRows,
        handleChangePlaceholderRowValue,
        handleBlurLastRow,
        isLastPlaceholderRowFocused,
        setIsLastPlaceholderRowFocused,
    };
};

export function useGenerateNewRows(
    gridId: string,
    backendService: AbstractBackendService,
    rows: Row[],
    columns: Column[],
    version: number,
    rowGeneratingFilters: RowGeneratingFilters,
) {
    const [exportMode, setExportMode] = React.useState<ExportMode>(ExportMode.Export);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const fillGridMutation = useMutation({
        mutationFn: (req: GridFillUpToNRowsRequest) => backendService.fillGridUpToNRows(req),
        onSuccess: (grid, req) => {
            const feGrid = convertBackendGridToFrontend(grid);
            queryClient.setQueryData(getReactQueryGridKey(grid.unique_id), feGrid);
            if (grid.last_export_task_id != null) {
                queryClient.setQueryData(getReactQueryTaskKey(grid.last_export_task_id), {
                    task_id: grid.last_export_task_id,
                    status: "PENDING",
                    exported_rows: 0,
                    desired_num_rows: req.num_rows,
                });
                void queryClient.refetchQueries({ queryKey: getReactQueryTaskKey(grid.last_export_task_id) });
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (e: any) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            if (typeof e === "string") {
                enqueueSnackbar(`Failed to export: ${e}`, { variant: "error" });
            } else if (e instanceof Error) {
                enqueueSnackbar(`Failed to export: ${e.message}`, { variant: "error" });
            } else {
                enqueueSnackbar("Failed to export", { variant: "error" });
            }
            setExportMode(ExportMode.Export);
        },
    });

    const handleGenerateNewRows = React.useCallback(
        async (rowCount: number, mode: ExportMode) => {
            setExportMode(mode);
            const request: GridFillUpToNRowsRequest = {
                grid_unique_id: gridId,
                grid_data: convertFrontendGridDataToGridData(columns, rows, version),
                row_generating_filters: convertFrontendRowGeneratingFilters(rowGeneratingFilters),
                num_rows: rowCount,
            };
            void queryClient.invalidateQueries({ queryKey: FREE_TRIAL_QUERY_KEY_FIRST_PART });
            await fillGridMutation.mutateAsync(request);
        },
        [columns, fillGridMutation, gridId, queryClient, rowGeneratingFilters, rows, version],
    );

    // Run a fill grid request with the current row count and the current columns
    const onLoadAllUnloadedCells = React.useCallback(async () => {
        // TODO: Have its own mode
        setExportMode(ExportMode.Generate);
        const request: GridFillUpToNRowsRequest = {
            grid_unique_id: gridId,
            grid_data: convertFrontendGridDataToGridData(columns, rows, version),
            row_generating_filters: convertFrontendRowGeneratingFilters(rowGeneratingFilters),
            num_rows: rows.length,
        };
        await fillGridMutation.mutateAsync(request);
    }, [columns, fillGridMutation, gridId, rowGeneratingFilters, rows, version]);

    return { handleGenerateNewRows, onLoadAllUnloadedCells, exportMode, setExportMode };
}

function getLabelFromColQuery(columnQuery: ColumnGeneratedBy): string {
    switch (columnQuery.type) {
        case "web_search":
            return columnQuery.query;
        case "crunchbase":
            return CRUNCHBASE_FIELD_DISPLAY_NAMES[columnQuery.fieldId].displayName;
        case "apollo":
            return "Employee";
        case "apollo_organization":
            return fieldToDisplayName(columnQuery.field);
        case "user_input":
            return "Custom";
    }
}

const APOLLO_PROPERTIES_DISPLAY_NAMES: Record<ApolloOrganizationField, string> = {
    technology: "Technologies",
    job_postings: "Job Postings",
    short_description: "LinkedIn Description",
};

function fieldToDisplayName(field: ApolloOrganizationField): string {
    return APOLLO_PROPERTIES_DISPLAY_NAMES[field];
}
