export interface ChangePasswordRequest {
    old_password: string;
    new_password: string;
}

export interface ChangePasswordResponse {
    detail: string;
    new_token: string;
}

export interface SelfServiceSignUpRequest {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
}

export interface ForgotPasswordRequest {
    email: string;
}

export interface ForgotPasswordResponse {
    detail: string;
}

export interface SelfServiceSignUpResponse {
    detail: string;
}

export interface VerifyEmailResponse {
    detail: string;
}

export interface MarkColumnAsWebsiteRequest {
    grid_data: BeGridData;
    column_id: string;
}

export interface MarkColumnAsWebsiteResponse {
    updated_grid_data: BeGridData;
}

export interface CreateGridFromCsvRequest {
    csv_data: string;
    name: string;
}

export interface CreateGridFromCsvResponse {
    unique_id: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FreeTrialUsageRequest {}

export interface FreeTrialUsageResponse {
    num_free_trial_rows_generated: number;
    has_reached_free_trial_limit: boolean;
}

export interface SignUpRequest {
    email: string;
    password: string;
    code: string;
    first_name: string;
    last_name: string;
}

export type PlanType =
    | "default-plan"
    | "trial"
    | "free-account"
    | "free-trial"
    | "paying-user"
    | "higher-tier-paying-user";
export interface GetInvitationStatusResponse {
    paid: boolean;
    plan_type: PlanType;
}

export interface CreateInvitationCheckoutSessionRequest {
    code: string;
}

export interface CreateInvitationCheckoutSessionResponse {
    url: string;
}

export interface CreateSelfServiceCheckoutSessionRequest {
    plan_type: PlanType;
}

export interface CreateSelfServiceCheckoutSessionResponse {
    url: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateTrialUserRequest {
    starting_public_grid_unique_id: string | undefined;
}

export interface CreateTrialUserResponse {
    token: string;
    starting_grid_unique_id: string | undefined;
}

export interface GetCurrentUserResponse {
    username: string;
    email: string | undefined;
    plan_type: PlanType;
    is_admin: boolean;
    is_email_verified: boolean;
    date_joined: string;
}

export interface LoginResponse {
    token: string;
}

type RunnerType =
    | "better-homemade"
    | "homemade"
    | "faster-homemade"
    | "faster-better-homemade"
    | "best-homemade"
    | "external";

export interface ShareGridResponse {
    public_grid_unique_id: string;
}

export interface BatchQueryCollectionRequest {
    collection: CollectionDetails;
    old_answers_grid: BeGridData;
    mode: RunnerType;
}

export type ColumnToolName =
    | "AnswerFromSearchEngine"
    | "AnswerFromContextWebPage"
    | "GeneralInstructionAssistant"
    | "FindWebPages";
export interface BeAgentToolConfig {
    tool_name: ColumnToolName;
    use_vision_if_page_scrape: boolean | undefined;
}

export interface BeFormatOptionsBoolean {
    type: "bool";
}

export interface BeFormatOptionsConcise {
    type: "concise";
}

export interface BeFormatOptionsInferred {
    type: "inferred";
}

export interface BeFormatOptionsNumber {
    type: "number";
}

export type BeFormatOptions =
    | BeFormatOptionsBoolean
    | BeFormatOptionsConcise
    | BeFormatOptionsInferred
    | BeFormatOptionsNumber;

export type ColumnQueryAnswerSourceAgent = {
    source_type: "AGENT";
    raw_query: string;
    context_cols: string[];
    tool_config: BeAgentToolConfig | undefined;
    format_options: BeFormatOptions | undefined;
};

export interface ColumnQueryAnswerSourceCrunchbase {
    source_type: "FOCUS_CB";
    field_id: BeSupportedFieldId;
}

export interface ApolloPersonFilters {
    person_titles: string[];
}

export interface ApolloPersonFiltersWithFallback {
    primary: ApolloPersonFilters;
    fallback: ApolloPersonFilters[];
}

export interface LegacyColumnQueryAnswerSourceConfigApollo {
    person_titles: string[];
}

export interface BeApolloPeopleEnrichmentSettings {
    enrich_single_person: boolean;
}

export interface ColumnQueryAnswerSourceApollo {
    source_type: "FOCUS_AP";
    /** @deprecated */
    source_config: LegacyColumnQueryAnswerSourceConfigApollo;
    person_filters: ApolloPersonFiltersWithFallback | undefined;
    enrichment_settings: BeApolloPeopleEnrichmentSettings | undefined;
}

export interface APTechnology {
    uid: string;
    name: string;
    category: string;
}

export interface ApolloOrganization {
    technology_names: string[];
    current_technologies: APTechnology[];
}

export type ApolloOrganizationField = "technology" | "job_postings" | "short_description";

export interface ColumnQueryAnswerSourceConfigApolloOrganization {
    field: ApolloOrganizationField;
}

export interface ColumnQueryAnswerSourceApolloOrganization {
    source_type: "FOCUS_AP_ORG";
    source_config: ColumnQueryAnswerSourceConfigApolloOrganization;
}

export type ColumnQueryAnswerSource =
    | ColumnQueryAnswerSourceAgent
    | ColumnQueryAnswerSourceCrunchbase
    | ColumnQueryAnswerSourceApollo
    | ColumnQueryAnswerSourceApolloOrganization;

export interface ColumnQueryAnswerSourceAgentWithTool extends ColumnQueryAnswerSourceAgent {
    tool_config: BeAgentToolConfig;
}

export type ColumnQueryAnswerSourceWithTool =
    | ColumnQueryAnswerSourceAgentWithTool
    | ColumnQueryAnswerSourceCrunchbase
    | ColumnQueryAnswerSourceApollo
    | ColumnQueryAnswerSourceApolloOrganization;

export interface ColumnQuery {
    answer_source: ColumnQueryAnswerSource;
    col_id: string;
}

export interface CollectionDetails {
    collection_desc: string;
    row_keys: BeRowKey[];
    col_queries: ColumnQuery[];
}

export interface CellLevelRowQuestions {
    row_key: BeRowKey;
    col_ids: string[];
}

export interface CellLevelQuestions {
    title: string;
    col_queries: ColumnQuery[];
    row_questions: CellLevelRowQuestions[];
}

export interface CellLevelQuestionResponse {
    cell_response: CellResponse;
    details: BeCellAnswerDetails | undefined;
}

export interface CellLevelQuestionRowResponse {
    row_key: BeRowKey;
    cell_response_by_col_id: Record<string, CellLevelQuestionResponse>;
}

export type BatchQueryCollectionResponse = {
    rows: CellLevelQuestionRowResponse[];
    used_columns_configs: Record<string, ColumnQueryAnswerSourceAgentWithTool>;
};

export interface CellLevelBatchQueryRequest {
    cell_level_questions: CellLevelQuestions;
    mode: RunnerType;
    old_answers_grid?: BeGridData;
}

export interface BeRowKey {
    title: string;
    value: string;
    external_identifiers: BeExternalIdentifiers | undefined;
}

export interface CellResponse {
    query_response: BeCellValueV2;
    citations: BeCitation[];
}

export interface BeCitation {
    link: string;
    title: string | undefined;
}

export interface AnswerIssueRequest {
    query: string;
    response: string;
    citations: string[];
}

export interface AnswerIssueResponse {
    id: number;
    unique_id: string;
    query: string;
    response: string;
    citations: string[];
    created_time: string;
    user: number;
}

export interface BeRowGeneratingFilter {
    predicate: Filter;
    add_as_column: boolean;
}

export interface BeRowGeneratingFiltersPagingInfo {
    last_fetched_uuid: string | undefined;
    total_count: number;
    fetched_count: number | undefined;
}

export interface BeRowGeneratingFilters {
    filters: BeRowGeneratingFilter[];
    paging_info: BeRowGeneratingFiltersPagingInfo | undefined;
}

export interface BeGrid {
    unique_id: string;
    name: string;
    created_at: string;
    last_edited: string;
    created_by: string;
    data: BeGridData;
    row_generating_filters: BeRowGeneratingFilters;
    visibility_filters: BeVisibilityFilters | undefined;
    last_export_task_id: string | undefined;
}

export interface BeGridData {
    key_column: BeKeyColumn;
    columns: BeGridColumn[];
    rows: BeGridRow[];
    version: number | undefined;
}

export interface BeKeyColumn {
    display_name: string;
}

export interface BeColumnGeneratedByWebSearch {
    query: string;
    type: "web_search";
    context_cols?: string[];
    tool_config: BeAgentToolConfig | undefined;
    format_options: BeFormatOptions | undefined;
}

export interface BeColumnGeneratedByCrunchbase {
    field_id: BeSupportedFieldId;
    type: "crunchbase";
}

export interface BeColumnGeneratedByApollo {
    type: "apollo";
    /** @deprecated Use person_filters instead */
    source_config: LegacyColumnQueryAnswerSourceConfigApollo;
    person_filters: ApolloPersonFiltersWithFallback | undefined;
    enrichment_settings: BeApolloPeopleEnrichmentSettings | undefined;
}

export interface BeColumnGeneratedByApolloOrganization {
    type: "apollo_organization";
    field: ApolloOrganizationField;
}

export interface BeColumnGeneratedByUserInput {
    type: "user_input";
}

export type BeColumnGeneratedBy =
    | BeColumnGeneratedByWebSearch
    | BeColumnGeneratedByCrunchbase
    | BeColumnGeneratedByApollo
    | BeColumnGeneratedByApolloOrganization
    | BeColumnGeneratedByUserInput;

export interface BeGridColumn {
    id: string;
    label: string;
    align: string | undefined;
    generated_by: BeColumnGeneratedBy | undefined;
    context_cols?: string[];
}

export interface BeGridRow {
    key: string;
    image_url: string | undefined;
    external_identifiers: BeExternalIdentifiers | undefined;
    columns: Record<string, BeGridRowValueWithCitations>;
}

export interface BeExternalIdentifierCrunchbase {
    type: "crunchbase";
    permalink?: string;
    uuid?: string;
}

export interface BeExternalIdentifierApollo {
    type: "apollo";
    domain_url?: string;
}

export interface BeCompanyExternalIdentifiers {
    type: "company";
    crunchbase: BeExternalIdentifierCrunchbase | undefined;
    apollo: BeExternalIdentifierApollo | undefined;
}

export type BeExternalIdentifiers = BeCompanyExternalIdentifiers;

export interface BeAgentCellAnswerDetails {
    source_type: "AGENT";
    raw_query: string;
}

export interface BeCrunchbaseCellAnswerDetails {
    source_type: "FOCUS_CB";
    field_id: string;
}

export interface BeApolloCellAnswerDetails {
    source_type: "FOCUS_AP";
    /** @deprecated */
    source_config: LegacyColumnQueryAnswerSourceConfigApollo;
    person_filters?: ApolloPersonFiltersWithFallback;
    enrichment_settings: BeApolloPeopleEnrichmentSettings | undefined;
}

export interface BeApolloOrganizationCellAnswerDetails {
    source_type: "FOCUS_AP_ORG";
    field: ApolloOrganizationField;
}

export type BeCellAnswerDetails =
    | BeAgentCellAnswerDetails
    | BeCrunchbaseCellAnswerDetails
    | BeApolloCellAnswerDetails
    | BeApolloOrganizationCellAnswerDetails;

export interface BeCellValueV2String {
    value: string;
    type: "string";
}

export interface BeAPPersonFieldString {
    type: "string";
    value: string;
}

export interface BeAPPersonFieldError {
    type: "error";
    error: string;
}

export interface BeAPPersonFieldMissing {
    type: "missing";
}

export interface BeAPPersonFieldNotRequested {
    type: "not_requested";
}

export type BeAPPersonField =
    | BeAPPersonFieldString
    | BeAPPersonFieldError
    | BeAPPersonFieldMissing
    | BeAPPersonFieldNotRequested;

export interface BeAPPersonPhoneNumber {
    value: string;
}

export interface BeAPPersonPhoneNumbers {
    type: "phone_numbers";
    values: BeAPPersonPhoneNumber[];
}

export interface BeAPPerson {
    /** Name is always defined */
    name: string;
    /** Name is always defined if not legacy */
    name_v2: BeAPPersonFieldString | undefined;
    email: string | undefined;
    email_v2: BeAPPersonField | undefined;
    linkedin: string | undefined;
    linkedin_v2: BeAPPersonField | undefined;
    title: string | undefined;
    title_v2: BeAPPersonField | undefined;
    phone_v2: BeAPPersonPhoneNumbers | BeAPPersonFieldMissing | BeAPPersonFieldNotRequested | undefined;
    headline_v2: BeAPPersonField | undefined;
    image_url: string | undefined;
    employment_history: BeAPPersonFieldEmploymentHistory | BeAPPersonFieldMissing | undefined;
}

export interface BeAPPersonFieldEmploymentHistory {
    type: "employment_history";
    values: BeAPPersonEmploymentHistoryEntry[];
}

export interface BeAPPersonEmploymentHistoryEntry {
    description: string | undefined;
    end_date: string | undefined;
    organization_id: string | undefined;
    organization_name: string | undefined;
    start_date: string | undefined;
    title: string | undefined;
}

export interface BeCellValueV2ApolloPeople {
    people: BeAPPerson[];
    total_count: number | undefined;
    type: "apollo_people";
}

export interface BeCellValueV2Boolean {
    value: boolean;
    reasoning?: string;
    type: "boolean";
}

export interface BeCellValueV2Missing {
    type: "missing";
}

export interface BeCellValueV2Error {
    error: string;
    type: "error";
}

export interface BeCellValueV2Technologies {
    technologies: APTechnology[];
    type: "technologies";
}

export interface BeApolloJobPosting {
    id: string;
    title: string;
    url: string;
    city?: string;
    state?: string;
    country?: string;
    last_seen_at: string;
    posted_at: string;
}

export interface BeCellValueV2JobPostings {
    job_postings: BeApolloJobPosting[];
    type: "job_postings";
}

export interface BeCellValueV2Unloaded {
    type: "unloaded";
}

export interface BeCellValueV2Number {
    type: "number";
    value: number;
}

export interface ValueLink {
    snippet: string;
    url: string;
    title: string;
}

export interface CellValueV2Links {
    type: "links";
    links: ValueLink[];
}

export type BeCellValueV2 =
    | BeCellValueV2String
    | BeCellValueV2ApolloPeople
    | BeCellValueV2Boolean
    | BeCellValueV2Missing
    | BeCellValueV2Error
    | BeCellValueV2Technologies
    | BeCellValueV2JobPostings
    | BeCellValueV2Unloaded
    | BeCellValueV2Number
    | CellValueV2Links;

export interface BeGridRowValueWithCitations {
    /** @deprecated */
    value: string;
    value_v2: BeCellValueV2 | undefined;
    citationUrls: string[];
    citations_v2: BeCitation[] | undefined;
    source_details: BeCellAnswerDetails | undefined;
}

export interface BeNullVisibilityFilter {
    type: "is-null" | "is-not-null";
}

export interface BeBooleanVisibilityFilter {
    type: "boolean";
    value: boolean;
}

export interface BeStringVisibilityFilter {
    type: "contains";
    value: string[];
}

export interface BeNumberVisibilityFilter {
    type: "number";
    eq: number[] | null;
    gte: number | null;
    lte: number | null;
}

export interface BeColumnVisibilityFilter {
    null_filter: BeNullVisibilityFilter | null;
    boolean_filter: BeBooleanVisibilityFilter | null;
    string_filter: BeStringVisibilityFilter | null;
    number_filter: BeNumberVisibilityFilter | null;
}

export interface BeVisibilityFilters {
    filters_by_column?: Record<string, BeColumnVisibilityFilter>;
}

export interface GridCreateRequest {
    name: string;
    data: BeGridData;
    row_generating_filters: BeRowGeneratingFilters;
    visibility_filters: BeVisibilityFilters;
}

export interface GridUpdateRequest {
    name?: string;
    data?: BeGridData;
    row_generating_filters?: BeRowGeneratingFilters;
    visibility_filters?: BeVisibilityFilters;
    last_export_task_id?: string;
}

export interface AddRowPatch {
    type: "add_row";
    row: BeGridRow;
    index?: number;
}

export interface AddColumnPatch {
    type: "add_column";
    column: BeGridColumn;
    row_values: BeGridRowValueWithCitations[];
    index?: number;
}

export interface DeleteRowPatch {
    type: "delete_row";
    index: number;
}

export interface DeleteColumnPatch {
    type: "delete_column";
    id: string;
}

export interface EditRowPatch {
    type: "edit_row";
    index: number;
    row: BeGridRow;
}

export interface EditColumnPatch {
    type: "edit_column";
    id: string;
    column: BeGridColumn;
    row_values: BeGridRowValueWithCitations[];
}

export type GridPatch =
    | AddRowPatch
    | AddColumnPatch
    | DeleteRowPatch
    | DeleteColumnPatch
    | EditRowPatch
    | EditColumnPatch;

export interface GridPatchRequest {
    version: number;
    patches?: GridPatch[];
    name?: string;
    row_generating_filters?: BeRowGeneratingFilters;
    visibility_filters?: BeVisibilityFilters;
}

export interface GridBatchGetRequest {
    ids: string[];
}

export type GridResponse = BeGrid;

export interface BeAvailableGrid {
    unique_id: string;
    name: string;
    created_at: string;
    last_edited: string;
}

export type GridGetAvailableResponse = BeAvailableGrid[];
export type GridBatchGetResponse = { [key: string]: BeGrid };

export type GridBuildWithQueryRequest = {
    query: string;
};

export interface GridBuildWithQueryAndFiltersRequest {
    query: string;
    filters: Array<GridBuildWithQueryAndFiltersRequestFilter>;
}

export interface GridBuildWithQueryAndFiltersRequestFilter {
    predicate: Filter;
    add_as_column: boolean;
}

export interface GridFillUpToNRowsRequest {
    num_rows: number;
    grid_unique_id: string;
    grid_data: BeGridData;
    row_generating_filters: BeRowGeneratingFilters;
}

export type ChangeGridFiltersRequest = {
    query: string;
    old_grid_data: BeGridData;
    filters: GridBuildWithQueryAndFiltersRequestFilter[];
    mode: RunnerType;
};

export type ChangeGridFiltersResponse = {
    grid_data: BeGridData;
    row_generating_filters: BeRowGeneratingFilters;
};

export interface TrackCompanyExportRequest {
    rows_to_export: BeRowKey[];
}

export interface TrackCompanyExportResponse {
    allowed_export_rows: BeRowKey[];
    total_export_usage: number;
    max_exportable_rows: number;
}

export type CrunchbaseAutoCompleteCollectionId =
    | "addresses"
    | "categories"
    | "category_groups"
    | "degrees"
    | "event_appearances"
    | "events"
    | "ipos"
    | "jobs"
    | "locations"
    | "location.groups"
    | "location.cities"
    | "location.regions"
    | "location.countries"
    | "organizations"
    | "organization.companies"
    | "organization.investors"
    | "organization.schools"
    | "ownerships"
    | "people"
    | "person.investors"
    | "principals"
    | "principal.investors";

export type CrunchbaseAutocompleteRequest = {
    query: string;
    collection_ids?: CrunchbaseAutoCompleteCollectionId[];
};

export interface CrunchbaseAutocompleteEntity {
    facet_ids: string[] | undefined;
    identifier: EntityIdentifier;
    short_description: string | undefined;
}

export type CrunchbaseAutocompleteResponse = {
    entities: CrunchbaseAutocompleteEntity[];
};

export interface ApolloTitleSearchRequest {
    query: string;
}

export interface ApolloTitleSearchResponse {
    titles: string[];
}

export interface PivotGridRequest {
    grid_data: BeGridData;
    new_key_col_id: string;
    grid_name: string;
}

export interface PivotGridResponse {
    grid_unique_id: string;
}

export interface CheckTaskStatusResponse {
    task_id: string;
    status: "PENDING" | "STARTED" | "PROGRESS" | "FAILURE" | "SUCCESS" | "RETRY" | "REVOKED";
    // TODO: DO we want the result
    // result: any;
    exported_rows: number;
    desired_num_rows: number;
}

export interface CancelTaskResponse {
    detail: string;
}

// FILTERS
// Money type
export interface Money {
    currency: CurrencyEnum;
    value_usd: number;
    value: number;
}

// EntityIdentifier type
export interface EntityIdentifier {
    uuid: string;
    entity_def_id: string;
    permalink?: string;
    value?: string;
    image_id?: string;
}

export function entityIdentifierToLocationIdentifier(ei: EntityIdentifier): LocationIdentifier {
    return {
        uuid: ei.uuid,
        entity_def_id: ei.entity_def_id,
        permalink: ei.permalink,
        value: ei.value,
        image_id: ei.image_id,
        // TODO: When we go this route, we don't have the type
    };
}

// LocationIdentifier type
export interface LocationIdentifier {
    uuid: string;
    entity_def_id: string;
    permalink?: string;
    value?: string;
    image_id?: string;
    location_type?: string;
}

export const LAST_EQUITY_FUNDING_TYPE_VALUES = [
    "angel",
    "convertible_note",
    "corporate_round",
    "debt_financing",
    "equity_crowdfunding",
    "grant",
    "initial_coin_offering",
    "non_equity_assistance",
    "post_ipo_debt",
    "post_ipo_equity",
    "post_ipo_secondary",
    "pre_seed",
    "private_equity",
    "product_crowdfunding",
    "secondary_market",
    "seed",
    "series_a",
    "series_b",
    "series_c",
    "series_d",
    "series_e",
    "series_f",
    "series_g",
    "series_h",
    "series_i",
    "series_j",
    "series_unknown",
    "undisclosed",
] as const;

export const NUM_EMPLOYEES_ENUM_VALUES = [
    "c_00001_00010",
    "c_00011_00050",
    "c_00051_00100",
    "c_00101_00250",
    "c_00251_00500",
    "c_00501_01000",
    "c_01001_05000",
    "c_05001_10000",
    "c_10001_max",
] as const;

export type DescriptionValue = string;
export type LastEquityFundingTypeValue = (typeof LAST_EQUITY_FUNDING_TYPE_VALUES)[number];
export type NumEmployeesEnumValue = (typeof NUM_EMPLOYEES_ENUM_VALUES)[number];
export type LastFundingAtValue = string;
export type LastFundingTotalValue = Money;
export type FundingTotalValue = Money;
export type WebsiteURLValue = string;
export type InvestorIdentifiersValue = EntityIdentifier;
export type CategoriesValue = EntityIdentifier;
export type CategoryGroupsValue = EntityIdentifier;
export type LocationGroupIdentifiersValue = EntityIdentifier;
export type LocationIdentifiersValue = LocationIdentifier;
// TODO: Make date?
export type CreatedAtValue = string;
export type FoundedOnValue = string;

const OPERATORS_BASIC = ["blank", "eq", "not_eq"] as const;
const OPERATORS_TEXT = [...OPERATORS_BASIC, "contains", "not_contains", "starts"] as const;
const OPERATORS_ENUM = [...OPERATORS_BASIC, "includes", "not_includes"] as const;
const OPERATORS_DATE = [...OPERATORS_BASIC, "between", "gte", "lte"] as const;
const OPERATORS_MONEY = [...OPERATORS_BASIC, "between", "gt", "gte", "lt", "lte"] as const;
const OPERATORS_DOMAIN = [
    "domain_blank",
    "domain_eq",
    "domain_includes",
    "not_domain_eq",
    "not_domain_includes",
] as const;

const OPERATORS_INCLUDES_ALL = [...OPERATORS_ENUM, "includes_all", "not_includes_all"] as const;

// Operators Types
export type OperatorsBasic = (typeof OPERATORS_BASIC)[number];
export type OperatorsText = (typeof OPERATORS_TEXT)[number];
export type OperatorsEnum = (typeof OPERATORS_ENUM)[number];
export type OperatorsDate = (typeof OPERATORS_DATE)[number];
export type OperatorsMoney = (typeof OPERATORS_MONEY)[number];
export type OperatorsDomain = (typeof OPERATORS_DOMAIN)[number];
export type OperatorsIncludesAll = (typeof OPERATORS_INCLUDES_ALL)[number];

export type OperatorsAllSuperType =
    | OperatorsBasic
    | OperatorsText
    | OperatorsEnum
    | OperatorsDate
    | OperatorsMoney
    | OperatorsDomain
    | OperatorsIncludesAll;

export const OPERATORS_BY_FIELD_ID: Record<Filter["field_id"], readonly OperatorsAllSuperType[]> = {
    description: OPERATORS_TEXT,
    num_employees_enum: OPERATORS_ENUM,
    last_equity_funding_type: OPERATORS_ENUM,
    last_funding_at: OPERATORS_DATE,
    last_funding_total: OPERATORS_MONEY,
    funding_total: OPERATORS_MONEY,
    website_url: OPERATORS_DOMAIN,
    investor_identifiers: OPERATORS_INCLUDES_ALL,
    categories: OPERATORS_INCLUDES_ALL,
    category_groups: OPERATORS_INCLUDES_ALL,
    location_group_identifiers: OPERATORS_INCLUDES_ALL,
    location_identifiers: OPERATORS_ENUM,
    created_at: OPERATORS_DATE,
    founded_on: OPERATORS_DATE,
};

// Predicate Types
export interface DescriptionPredicate {
    operator_id: OperatorsText;
    values: DescriptionValue[];
    type: "predicate";
    field_id: "description";
}

export interface NumEmployeesEnumPredicate {
    operator_id: OperatorsEnum;
    values: NumEmployeesEnumValue[];
    type: "predicate";
    field_id: "num_employees_enum";
}

export interface LastEquityFundingTypePredicate {
    operator_id: OperatorsEnum;
    values: LastEquityFundingTypeValue[];
    type: "predicate";
    field_id: "last_equity_funding_type";
}

export interface LastFundingAtPredicate {
    operator_id: OperatorsDate;
    values: LastFundingAtValue[];
    type: "predicate";
    field_id: "last_funding_at";
}

export interface LastFundingTotalPredicate {
    operator_id: OperatorsMoney;
    values: LastFundingTotalValue[];
    type: "predicate";
    field_id: "last_funding_total";
}

export interface FundingTotalPredicate {
    operator_id: OperatorsMoney;
    values: FundingTotalValue[];
    type: "predicate";
    field_id: "funding_total";
}

export interface WebsiteURLPredicate {
    operator_id: OperatorsDomain;
    values: WebsiteURLValue[];
    type: "predicate";
    field_id: "website_url";
}

export interface InvestorIdentifiersPredicate {
    operator_id: OperatorsIncludesAll;
    values: InvestorIdentifiersValue[];
    type: "predicate";
    field_id: "investor_identifiers";
}

export interface CategoriesPredicate {
    operator_id: OperatorsIncludesAll;
    values: CategoriesValue[];
    type: "predicate";
    field_id: "categories";
}

export interface CategoryGroupsPredicate {
    operator_id: OperatorsIncludesAll;
    values: CategoryGroupsValue[];
    type: "predicate";
    field_id: "category_groups";
}

export interface LocationGroupIdentifiersPredicate {
    operator_id: OperatorsIncludesAll;
    values: LocationGroupIdentifiersValue[];
    type: "predicate";
    field_id: "location_group_identifiers";
}

export interface LocationIdentifiersPredicate {
    operator_id: OperatorsEnum;
    values: LocationIdentifiersValue[];
    type: "predicate";
    field_id: "location_identifiers";
}

export interface CreatedAtPredicate {
    operator_id: OperatorsDate;
    values: CreatedAtValue[];
    type: "predicate";
    field_id: "created_at";
}

export interface FoundedOnPredicate {
    operator_id: OperatorsDate;
    values: FoundedOnValue[];
    type: "predicate";
    field_id: "founded_on";
}

// Supported Field Ids
export type BeSupportedFieldId =
    | "description"
    | "num_employees_enum"
    | "last_equity_funding_type"
    | "last_funding_at"
    | "last_funding_total"
    | "funding_total"
    | "website_url"
    | "investor_identifiers"
    | "categories"
    | "category_groups"
    | "location_group_identifiers"
    | "location_identifiers"
    | "rank"
    | "created_at"
    | "founded_on"
    | "linkedin";

export type Filter =
    | DescriptionPredicate
    | NumEmployeesEnumPredicate
    | LastEquityFundingTypePredicate
    | LastFundingAtPredicate
    | LastFundingTotalPredicate
    | FundingTotalPredicate
    | WebsiteURLPredicate
    | InvestorIdentifiersPredicate
    | CategoriesPredicate
    | CategoryGroupsPredicate
    | LocationGroupIdentifiersPredicate
    | LocationIdentifiersPredicate
    | CreatedAtPredicate
    | FoundedOnPredicate;

export type CurrencyEnum =
    | "AED"
    | "AFN"
    | "ALL"
    | "AMD"
    | "ANG"
    | "AOA"
    | "ARS"
    | "AUD"
    | "AWG"
    | "AZN"
    | "BAM"
    | "BBD"
    | "BDT"
    | "BGN"
    | "BHD"
    | "BIF"
    | "BMD"
    | "BND"
    | "BOB"
    | "BRL"
    | "BSD"
    | "BTN"
    | "BWP"
    | "BYN"
    | "BYR"
    | "BZD"
    | "CAD"
    | "CDF"
    | "CHF"
    | "CLF"
    | "CLP"
    | "CNY"
    | "COP"
    | "CRC"
    | "CUC"
    | "CUP"
    | "CVE"
    | "CZK"
    | "DJF"
    | "DKK"
    | "DOP"
    | "DZD"
    | "EGP"
    | "ERN"
    | "ETB"
    | "EUR"
    | "FJD"
    | "FKP"
    | "GBP"
    | "GEL"
    | "GHS"
    | "GIP"
    | "GMD"
    | "GNF"
    | "GTQ"
    | "GYD"
    | "HKD"
    | "HNL"
    | "HRK"
    | "HTG"
    | "HUF"
    | "IDR"
    | "ILS"
    | "INR"
    | "IQD"
    | "IRR"
    | "ISK"
    | "JMD"
    | "JOD"
    | "JPY"
    | "KES"
    | "KGS"
    | "KHR"
    | "KMF"
    | "KPW"
    | "KRW"
    | "KWD"
    | "KYD"
    | "KZT"
    | "LAK"
    | "LBP"
    | "LKR"
    | "LRD"
    | "LSL"
    | "LTL"
    | "LVL"
    | "LYD"
    | "MAD"
    | "MDL"
    | "MGA"
    | "MKD"
    | "MMK"
    | "MNT"
    | "MOP"
    | "MRO"
    | "MUR"
    | "MVR"
    | "MWK"
    | "MXN"
    | "MYR"
    | "MZN"
    | "NAD"
    | "NGN"
    | "NIO"
    | "NOK"
    | "NPR"
    | "NZD"
    | "OMR"
    | "PAB"
    | "PEN"
    | "PGK"
    | "PHP"
    | "PKR"
    | "PLN"
    | "PYG"
    | "QAR"
    | "RON"
    | "RSD"
    | "RUB"
    | "RWF"
    | "SAR"
    | "SBD"
    | "SCR"
    | "SDG"
    | "SEK"
    | "SGD"
    | "SHP"
    | "SKK"
    | "SLL"
    | "SOS"
    | "SRD"
    | "SSP"
    | "STD"
    | "SVC"
    | "SYP"
    | "SZL"
    | "THB"
    | "TJS"
    | "TMT"
    | "TND"
    | "TOP"
    | "TRY"
    | "TTD"
    | "TWD"
    | "TZS"
    | "UAH"
    | "UGX"
    | "USD"
    | "UYU"
    | "UZS"
    | "VEF"
    | "VND"
    | "VUV"
    | "WST"
    | "XAF"
    | "XAG"
    | "XAU"
    | "XBA"
    | "XBB"
    | "XBC"
    | "XBD"
    | "XCD"
    | "XDR"
    | "XOF"
    | "XPD"
    | "XPF"
    | "XPT"
    | "YER"
    | "ZAR"
    | "ZMK"
    | "ZMW"
    | "xts";
