import * as React from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Switch,
    Typography,
    Autocomplete,
    TextField,
    AutocompleteRenderInputParams,
} from "@mui/material";
import { Export, ArrowDown2, ArrowRight2 } from "iconsax-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCurrentUserQuery } from "../context/userContext";
import { useBoolean } from "../utils/hooks";
import { getBackendService } from "../services/cb-backend";
import { getReactQueryGridKey } from "../react-query/queryKeys";
import {
    convertBackendGridToFrontend,
    convertFrontendGridDataToGridData,
    convertFrontendRowGeneratingFilters,
} from "./gridSerialization";
import {
    Column,
    ExportMode,
    getMaxGeneratableRowsLimit,
    hasTrialFeatures,
    isRealRow,
    Row,
    RowGeneratingFilters,
} from "./grid";
import { GridFillUpToNRowsRequest } from "../services/cb-backend-types";
import { PositiveIntegerInput } from "../reusable/positiveIntegerInput";
import ButtonWithDisabledTooltip from "../reusable/buttonWithDisabledTooltip";
import { MAX_ROWS_PER_COMPANY } from "../csv/exportToCsv";
import { useFreeTrialUsage } from "./userUsageQuery";
import { ValueChip } from "../home/filters/valueChip";

interface ExportCsvDialogProps {
    totalRowCount: number;
    isOpen: boolean;
    rows: Row[];
    visibleRows: Row[];
    columns: Column[];
    rowGeneratingFilters: RowGeneratingFilters;
    version: number;
    gridId: string;
    name: string;
    setExportMode: React.Dispatch<React.SetStateAction<ExportMode>>;
    onClose: () => void;
    exportToCsv: (
        rows: Row[],
        columns: Column[],
        fileName: string,
        rowCount?: number,
        maxRowsPerCompany?: number,
    ) => Promise<void>;
}

function areRowsLoaded(rows: Row[]) {
    return rows.filter(isRealRow).every(row => Object.values(row.data).every(value => value.value.type !== "unloaded"));
}

export const ExportCsvDialog: React.FC<ExportCsvDialogProps> = ({
    totalRowCount,
    isOpen,
    rows,
    visibleRows,
    columns,
    rowGeneratingFilters,
    gridId,
    version,
    name,
    setExportMode,
    onClose,
    exportToCsv,
}) => {
    const user = useCurrentUserQuery();
    const maxRowsForExport = React.useMemo(() => {
        if (user.data == null) {
            return rows.length;
        }
        if (hasTrialFeatures(user.data.plan_type)) {
            return rows.length;
        }
        const maxGeneratableRows = getMaxGeneratableRowsLimit(user.data.plan_type);
        return maxGeneratableRows != null ? Math.min(totalRowCount, maxGeneratableRows) : totalRowCount;
    }, [user.data, totalRowCount, rows.length]);
    const [rowCount, setRowCount] = React.useState<number | null>(Math.min(50, maxRowsForExport));
    const [loadingExport, setLoadingExport] = React.useState(false);
    const [onlyExportVisibleRows, setOnlyExportVisibleRows] = React.useState(visibleRows.length < rows.length);
    const [excludedColumnIds, setExcludedColumnIds] = React.useState<string[]>([]);

    const onChangeOnlyExportVisibleRows = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setOnlyExportVisibleRows(e.target.checked);
        },
        [setOnlyExportVisibleRows],
    );

    const patchedOnlyExportVisibleRows = onlyExportVisibleRows && visibleRows.length < rows.length;

    const backendService = React.useMemo(() => getBackendService(), []);

    const queryClient = useQueryClient();

    const fillGridMutation = useMutation({
        mutationFn: (req: GridFillUpToNRowsRequest) => backendService.fillGridUpToNRows(req),
        onMutate: data => {
            setLoadingExport(true);
            setExportMode(ExportMode.Export);
            return data;
        },
        onSuccess: grid => {
            const feGrid = convertBackendGridToFrontend(grid);
            queryClient.setQueryData(getReactQueryGridKey(gridId), feGrid);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (e: any) => {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            if (typeof e === "string") {
                alert(`Failed to export: ${e}`);
            } else if (e instanceof Error) {
                alert(`Failed to export: ${e.message}`);
            } else {
                alert("Failed to export");
            }
        },
    });

    const hasSomeApolloPersonColumn = React.useMemo(() => {
        return columns.some(column => column.generatedBy?.type == "apollo");
    }, [columns]);

    const { value: isAdvancedSettingsOpen, toggleValue: toggleAdvancedSettings } = useBoolean(false);
    const [maxRowsPerCompany, setMaxRowsPerCompany] = React.useState<number | null>(MAX_ROWS_PER_COMPANY);

    const handleChangeMaxRowsPerCompany = React.useCallback(
        (value: number | null) => {
            setMaxRowsPerCompany(value);
        },
        [setMaxRowsPerCompany],
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleExport = React.useCallback(async () => {
        if (rowCount == null) {
            return;
        }
        if (patchedOnlyExportVisibleRows || rowCount <= rows.length) {
            const rowsToExport = patchedOnlyExportVisibleRows ? visibleRows : rows;
            if (!areRowsLoaded(rowsToExport) && user.data?.plan_type !== "trial") {
                enqueueSnackbar("Preparing rows for export", {
                    variant: "info",
                    disableWindowBlurListener: true,
                    anchorOrigin: { vertical: "top", horizontal: "center" },
                });
                await fillGridMutation.mutateAsync({
                    grid_unique_id: gridId,
                    grid_data: convertFrontendGridDataToGridData(columns, rowsToExport, version),
                    row_generating_filters: convertFrontendRowGeneratingFilters(rowGeneratingFilters),
                    num_rows: rowCount,
                });
                onClose();
                return;
            }

            const filteredColumns = columns.filter(column => !excludedColumnIds.includes(column.id));

            void exportToCsv(rowsToExport, filteredColumns, `${name}.csv`, undefined, maxRowsPerCompany ?? undefined);
            enqueueSnackbar("Exported successfully", {
                variant: "success",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            onClose();
            return;
        } else if (rowCount < 0 || rowCount > totalRowCount) {
            enqueueSnackbar(`Invalid row count: ${rowCount}. The row count must be between 1 and ${totalRowCount}.`, {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            return;
        } else {
            enqueueSnackbar("Generating new rows for export", {
                variant: "info",
                disableWindowBlurListener: true,
                anchorOrigin: { vertical: "top", horizontal: "center" },
            });
            await fillGridMutation.mutateAsync({
                grid_unique_id: gridId,
                grid_data: convertFrontendGridDataToGridData(columns, rows, version),
                row_generating_filters: convertFrontendRowGeneratingFilters(rowGeneratingFilters),
                num_rows: rowCount,
            });
        }
        setLoadingExport(false);
        onClose();
    }, [
        rowCount,
        patchedOnlyExportVisibleRows,
        rows,
        totalRowCount,
        onClose,
        visibleRows,
        user.data?.plan_type,
        exportToCsv,
        columns,
        name,
        maxRowsPerCompany,
        enqueueSnackbar,
        fillGridMutation,
        gridId,
        version,
        rowGeneratingFilters,
        excludedColumnIds,
    ]);

    const handleKeyUp = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                void handleExport();
            }
        },
        [handleExport],
    );

    React.useEffect(() => {
        // handle key up
        window.addEventListener("keyup", handleKeyUp);
        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [handleKeyUp]);

    const onChange = React.useCallback(
        (value: number | null) => {
            setRowCount(value);
        },
        [setRowCount],
    );

    const isTrialUser = user.data != null && hasTrialFeatures(user.data.plan_type);

    const usage = useFreeTrialUsage();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Export</DialogTitle>
            <DialogContent
                sx={{
                    minHeight: 80,
                    width: 400,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: 2,
                    }}
                >
                    <PositiveIntegerInput
                        disabled={patchedOnlyExportVisibleRows}
                        value={patchedOnlyExportVisibleRows ? visibleRows.length : rowCount}
                        onChange={onChange}
                        min={1}
                        max={maxRowsForExport}
                        size="small"
                    />
                    <Typography>{`of ${totalRowCount} rows`}</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", mt: 0.5, whiteSpace: "pre-line" }}>
                    {(!patchedOnlyExportVisibleRows || isTrialUser) && (
                        <Typography variant="caption" color="text.secondary">
                            {/* TODO: FIx thsi fomrulation for unloaded */}
                            {isTrialUser
                                ? `As a free user, you can export up to the ${maxRowsForExport} rows that you have loaded. To generate more, sign up for the free trial or a paid plan.`
                                : `${rows.length} are available for instant export, the rest will be generated. `}
                            {user.data?.plan_type === "free-trial" && usage.data != null
                                ? `\nYou've generated ${usage.data.num_free_trial_rows_generated} of ${maxRowsForExport} your free trial rows.`
                                : ""}
                        </Typography>
                    )}
                    {visibleRows.length < rows.length && (
                        <FormControlLabel
                            sx={{
                                mt: 2,
                                pl: 1,
                            }}
                            control={
                                <Switch
                                    checked={patchedOnlyExportVisibleRows}
                                    onChange={onChangeOnlyExportVisibleRows}
                                    color="secondary"
                                    size="small"
                                />
                            }
                            label={<Typography variant="body2">Only export visible rows</Typography>}
                        />
                    )}
                </Box>
                {hasSomeApolloPersonColumn && (
                    <ExportSettings
                        isOpen={isAdvancedSettingsOpen}
                        toggleOpen={toggleAdvancedSettings}
                        maxRowsPerCompany={maxRowsPerCompany}
                        onChangeMaxRowsPerCompany={handleChangeMaxRowsPerCompany}
                        columns={columns}
                        excludedColumnIds={excludedColumnIds}
                        setExcludedColumnIds={setExcludedColumnIds}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    sx={{
                        textTransform: "none",
                        typography: "body2",
                        borderRadius: 8,
                    }}
                    variant="text"
                    color="secondary"
                >
                    Cancel
                </Button>
                <ButtonWithDisabledTooltip
                    startIcon={loadingExport ? <CircularProgress size="16px" /> : <Export size="16" />}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={handleExport}
                    variant="contained"
                    disabledTooltip={rowCount == null ? `Enter a number between 1 and ${maxRowsForExport}` : undefined}
                    color="secondary"
                    sx={{
                        textTransform: "none",
                        borderRadius: 8,
                        typography: "body2",
                    }}
                >
                    Export
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};

import { Paper } from "@mui/material";

const ExportSettings: React.FC<{
    isOpen: boolean;
    toggleOpen: () => void;
    maxRowsPerCompany: number | null;
    onChangeMaxRowsPerCompany: (value: number | null) => void;
    columns: Column[];
    excludedColumnIds: string[];
    setExcludedColumnIds: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({
    isOpen,
    toggleOpen,
    maxRowsPerCompany,
    onChangeMaxRowsPerCompany,
    columns,
    excludedColumnIds,
    setExcludedColumnIds,
}) => {
    const excludedColumns = React.useMemo(() => {
        return columns.filter(column => excludedColumnIds.includes(column.id));
    }, [columns, excludedColumnIds]);

    const nonExcludedColumns = React.useMemo(() => {
        return columns.filter(column => !excludedColumnIds.includes(column.id));
    }, [columns, excludedColumnIds]);

    const handleChangeExcludedColumns = React.useCallback(
        (event: React.SyntheticEvent, value: Column[]) => {
            setExcludedColumnIds(value.map(column => column.id));
        },
        [setExcludedColumnIds],
    );

    const renderInput = React.useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Select columns to exclude"
                InputProps={{
                    ...params.InputProps,
                    sx: {
                        typography: "body2",
                    },
                }}
            />
        );
    }, []);

    const optionLabel = React.useCallback((option: Column) => {
        return option.label;
    }, []);

    const CustomPaper = React.useCallback((props: React.ComponentProps<typeof Paper>) => {
        return (
            <Paper
                {...props}
                elevation={8}
                sx={{
                    "& .MuiAutocomplete-option": { typography: "body2" },
                }}
            />
        );
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                    Settings
                </Typography>
                <IconButton size="small" onClick={toggleOpen}>
                    {isOpen ? <ArrowDown2 size={16} /> : <ArrowRight2 size={16} />}
                </IconButton>
            </Box>
            {isOpen && (
                <Box sx={{ display: "flex", flexDirection: "column", ml: 0, mt: 0.5 }}>
                    <Typography variant="caption">Max rows per company</Typography>
                    <PositiveIntegerInput
                        value={maxRowsPerCompany}
                        onChange={onChangeMaxRowsPerCompany}
                        min={1}
                        max={100}
                        size="small"
                    />
                    {columns.length > 1 && (
                        <>
                            <Typography variant="caption" sx={{ mt: 1 }}>
                                Exclude columns
                            </Typography>
                            <Autocomplete
                                multiple
                                options={nonExcludedColumns}
                                getOptionLabel={optionLabel}
                                value={excludedColumns}
                                onChange={handleChangeExcludedColumns}
                                renderInput={renderInput}
                                size="small"
                                PaperComponent={CustomPaper}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <ValueChip label={option.label} {...getTagProps({ index })} key={option.id} />
                                    ))
                                }
                            />
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};
